import '@/helpers/cookie-consent';

const getSectionNames = async () => {
    // Include subdirectories by setting the second parameter to true
    const context = require.context('@/sections', true, /\.js$/);

    return context.keys().map(filePath => {
        // Extract the section name with folder structure
        return filePath.replace('./', '').replace('.js', '');
    });
};

const importSections = async () => {
    const sectionNames = await getSectionNames();

    // Check if any sections are present in the DOM
    const hasSections = sectionNames.some(name =>
        document.querySelector(`section[data-js-import="${name}"]`)
    );

    if (!hasSections) {
        console.log('No sections to import.');
        return;
    }

    // Proceed with importing sections if any are present
    sectionNames
        .filter(name => document.querySelector(`section[data-js-import="${name}"]`))
        .forEach(name => {
            import(
                `@/sections/${name}`
                );
        });
};

// Call the function to import sections
importSections();
