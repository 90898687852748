'use strict';

import DataTransport from '@/helpers/data-transport';
import AlertMessage from "@/helpers/alert-message";

(function() {
    let packData = null;
    try {
        packData = packObject;
    } catch (e) {
        console.error("packObject is not defined");
        return;
    }
    const transport = new DataTransport();
    const alertMessage = new AlertMessage();
    alertMessage.SetActiveAlert('user-profile-update');

    const userProfileFrm = document.querySelector('[data-role="user-profile-form"]');
    userProfileFrm.addEventListener('submit', async function (e) {
        e.preventDefault();

        // Get the submit button
        const submitButton = this.querySelector('button[type="submit"]');
        const submitButtonTxt = submitButton.innerHTML;

        alertMessage.HideAlert();

        // Create a FormData object to capture all form inputs
        const formData = new FormData(this);

        /*
            // For debugging or customization purposes, log the form data as key-value pairs
            for (let [key, value] of formData.entries()) {
                console.log(key, value);
            }
        */

        submitButton.innerHTML = "Please Wait";
        submitButton.disabled = true;

        await transport.PostFormData(`/admincp/UserProfile/?userId=${packData.userId}&exec=updateProfile`, formData)
            .then(async data => {
                console.log(data);
                if (typeof data === 'object') {
                    console.log(data);
                    if (data.error) {
                        alertMessage.ShowError(data.message);
                        submitButton.innerHTML = submitButtonTxt;
                        submitButton.disabled = false;
                    } else {
                        // success
                        alertMessage.ShowSuccess(data.message);
                        submitButton.innerHTML = submitButtonTxt;
                        submitButton.disabled = false;
                    }
                } else {
                    // Raw Text;
                }
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });
    });
})();