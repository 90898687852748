import { Splide } from '@splidejs/splide';

const splide = new Splide('.js-pricing-cards-slider', {
    type: 'loop',
    perPage: 1,
    perMove: 1,
    arrows: {prev: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 12L10 8L6 4" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/></svg>', next: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 12L10 8L6 4" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/></svg>'},
    gap: '1.5rem',
    mediaQuery: 'min',
    pagination: false,
    focus: 'center',
    padding: {right: '5%', left: '5%'},
    breakpoints: {
        576: {
            perPage: 2,
        },
        991: {
            perPage: 3,
        },
        1220: {
            perPage: 4,
        },
        1440: {
            perPage: 5,
        },
        1700: {
            perPage: 6,
        },
        1900: {
            perPage: 7,
        },
        2100: {
            perPage: 8,
        },
    },
});
splide.mount();
