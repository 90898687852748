'use strict';

import DataTransport from "@/helpers/data-transport";
import AlertMessage from "@/helpers/alert-message";

(function() {
    const transport = new DataTransport();

    const locations = document.querySelectorAll('[data-role="js_location_enable"]');
    locations.forEach((e) => {
        e.addEventListener('click', (evt) => {
            const locationId = e.getAttribute('data-locationId');
            const checked = e.checked ? 1 : 0;

            transport.GetRawResponse('/admincp/UpdateLocation/', { locationId: locationId, enabled: checked})
                .then(async data => {
                    console.log(data);
                })
                .catch(error => console.error('Failed to fetch data:', error))
                .finally(() => {

                });
        })
    })
})();