import { Countdown } from '@/helpers/countdown';

function init() {

    const promoEnding = document.querySelector('.js-promo-countdown')
        ? document.querySelector('.js-promo-countdown').dataset.countdown
        : '';

    const onCountdownFinish = () => {
        document.querySelector('.js-promo-countdown-timer').style.display = 'none';
    };

    new Countdown('.js-promo-countdown-timer', promoEnding, {
        useDays: true,
        onFinish: onCountdownFinish,
    });
}

init();
