'use strict';


import DataTransport from "@/helpers/data-transport";
import AlertMessage from "@/helpers/alert-message";
import ServerStatus from "@/sections/gameservers/helper/server-status";

(function() {
    let packData = null;
    try {
        packData = packObject;
    } catch (e) {
        console.error("packObject is not defined");
        return;
    }
    const serverStatus = new ServerStatus(packData.gameServerId, packData.showLog);
})();

(async function() {
    const url = new URL(window.location.href);

    const gameServerId = url.searchParams.get('gameServerId');
    const initPath = url.searchParams.get('path') ?? "";
    const transport = new DataTransport();
    const alertMessage = new AlertMessage();
    const fileList = document.querySelector('[data-role="file-list"]');
    const loader = '<div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden">Loading...</span></div>';

    if (!fileList) {
        console.error("file-list data role does not exist in our context. Something has been missed.");
        return;
    }
    fileList.innerHTML = loader;
    LoadPath(initPath);

    async function LoadPath(path) {
        await transport.GetRawResponse(`/FileManager?gameServerId=${gameServerId}&exec=list&path=${path}`)
            .then(async data => {
                if (typeof data === 'object') {

                    if (data.error) {
                        alertMessage.ShowError(data.message);
                    } else {

                    }
                } else {
                    alertMessage.HideAlert();

                    // Raw Text;
                    fileList.innerHTML = data;

                    const fileManagerPath = document.querySelector('[data-role="file-manager-path"]');


                    document.querySelectorAll('[data-role="file-manager-select"]').forEach((e) => {
                        e.addEventListener("dblclick", (pathLink) => {
                            const path = (fileManagerPath.getAttribute("data-id") ? fileManagerPath.getAttribute("data-id")  : "") + e.getAttribute("data-path");
                            url.searchParams.set("path", path);
                            history.pushState({ path: path }, '', url);
                            LoadPath(path);
                        });


                        e.addEventListener("click", (pathLink) => {
                            document.querySelectorAll('[data-role="file-manager-select"]').forEach((r) => {
                                r.classList.remove("file-selected");
                            });
                            e.classList.add("file-selected");
                        })
                    });

                    if (document.querySelector('[data-role="file-manager-filename"]')) {
                        document.querySelector('[data-role="file-manager-filename"]').addEventListener("click", (e) => {
                            const path = (fileManagerPath.getAttribute("data-id") ? fileManagerPath.getAttribute("data-id") : "");
                            url.searchParams.set("path", path);
                            history.pushState({path: path}, '', url);
                            LoadPath(path);
                        });
                    }

                    if (document.querySelector('[data-role="file-manager-save-file-form"]')) {
                        document.querySelector('[data-role="file-manager-save-file-form"]').addEventListener("submit", async (e) => {
                            e.preventDefault();
                            const id = e.target.getAttribute("data-id");
                            const contents = e.target.querySelector('[data-role="file-manager-contents"]');
                            const submitBtn = e.target.querySelector('[data-role="file-manager-save-btn"]');
                            const submitBtnText = submitBtn.innerHTML;
                            if (!id) {
                                alertMessage.ShowError("Something has gone wrong. Reload the page.");
                                return;
                            }

                            submitBtn.disabled = true;
                            submitBtn.innerHTML = "Saving - Please Wait";

                            await new Promise(r => setTimeout(r, 2000));

                            await transport.GetRawResponse(`/FileManager?gameServerId=${gameServerId}&exec=write`, { id: id, contents: contents.value })
                                .then(async data => {
                                    //console.log(data);
                                    if (typeof data === 'object') {
                                        if (data.error) {
                                            alertMessage.ShowError(data.message);
                                        } else {
                                            submitBtn.disabled = false;
                                            submitBtn.innerHTML = submitBtnText;
                                            alertMessage.ShowSuccess(data.message);
                                        }
                                    } else {

                                    }
                                })
                                .catch(error => console.error('Failed to fetch data:', error))
                                .finally(() => {

                                });
                        })
                    }
                }
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });
    }

    // Handle browser back/forward navigation
    window.addEventListener('popstate', (event) => {
        // Get the state from the history entry
        if (event.state && event.state.path) {
            // Load the directory associated with the state
            LoadPath(event.state.path);
        } else {
            LoadPath("");
        }
    });
})();