'use strict';

import DataTransport from '@/helpers/data-transport';
import AlertMessage from "@/helpers/alert-message";
import rangeSlider from 'rangeslider-pure';

(function() {
    let packData = null;
    try {
        packData = packObject;
    } catch (e) {
        console.error("packObject is not defined");
        return;
    }

    const alertMessage = new AlertMessage();
    const transport = new DataTransport();

    // Initialize variables and elements
    const playersSlider = document.getElementById('playerSlider');
    const playerSliderContent = document.getElementById('playerSliderContent');
    const playerSliderOutput = document.getElementById('playerSliderOutput');
    const daysSlider = document.getElementById('daysSlider');
    const daysSliderOutput = document.getElementById('daysSliderOutput');
    const locationSelect = document.getElementById('locationSelect');
    const locationContent = document.getElementById('locationContent');
    const configureForm = document.getElementById('configureForm');
    const configureFormBtn = document.getElementById('configureFormBtn');
    const configureFormBtnText = configureFormBtn.innerHTML;
    const daysInputContent = document.getElementById('daysInputContent');
    const priceSliderContent = document.getElementById('priceSliderContent');
    const priceToPayContent = document.getElementById('priceToPayContent');

    // Set the max value to however many pricing periods we have
    function configureDaysSlider() {
        daysSlider.max = packData.ppp.length - 1;
    }
    configureDaysSlider();


    // Update calculations based on slider values
    function updateCalculations(updateUrl) {
        // Day Slider
        const daySliderValue = daysSlider.value;
        daysInputContent.innerText = packData.days[daySliderValue];
        daysSliderOutput.innerText = packData.days[daySliderValue];
        if (updateUrl) updateUrlParameter('days', packData.days[daySliderValue]);

        // Player Slider
        if (playersSlider.value < packData.clusterCurrentUsedPlayers)
            playersSlider.value = packData.clusterCurrentUsedPlayers;
        const playerSliderValue = playersSlider.value;
        playerSliderContent.innerText = playerSliderValue;
        playerSliderOutput.innerText = playerSliderValue;
        if (updateUrl) updateUrlParameter('players', playerSliderValue);

        // Location Dropdown
        const locationValue = locationSelect.value;
        locationContent.innerHTML = locationSelect[locationSelect.selectedIndex].text;
        if (updateUrl)
            updateUrlParameter('location', locationValue);


        // Update Price
        const price = packData.isCluster ? calculateReconfiguredPrice() : calculatePrice();
        priceSliderContent.innerHTML = price;

        // Any credit?
        if (priceToPayContent)
            priceToPayContent.innerHTML = Math.max(0, price - packData.credit).toFixed(2);
    }

    // Calculate price for NEW servers
    function calculatePrice() {
        const pricePerPlayer = packData.ppp[daysSlider.value];
        const players = playersSlider.value;
        const numberOfDays = packData.days[daysSlider.value];

        const price = (pricePerPlayer * players) * numberOfDays;
        return price.toFixed(2);
    }

    // Calculate price for reconfiguring EXISTING servers
    function calculateReconfiguredPrice() {
        const pricePerPlayer = packData.ppp[daysSlider.value];
        const players = playersSlider.value;
        const additionalPlayers = Math.max(players - packData.clusterMaxPlayers, 0);
        const numberOfDays = packData.days[daysSlider.value];

        let price = 0;
        // First thing we do is check if there is any additional players, and if so, we would take our PPP and * our additional players by remainingtime
        if (additionalPlayers > 0 && packData.clusterRemainingDays > 0) {
            price += (pricePerPlayer * additionalPlayers) * packData.clusterRemainingDays;
        }
        // Next update we need to see if they are also trying to adjust their contract length...
        if (numberOfDays > 0) {
            price += (pricePerPlayer * players) * numberOfDays;
        }

        return price.toFixed(2);
    }

    // Update URL parameters
    function updateUrlParameter(key, value) {
        const url = new URL(window.location);
        url.searchParams.set(key, value);
        history.replaceState(null, '', url);
    }

    // Check URL for predefined values and initialize sliders
    function checkURL() {
        const url = new URL(window.location);
        let daysParam = parseInt(url.searchParams.get('days'));
        const playersParam = parseInt(url.searchParams.get('players'));
        const locationParam = parseInt(url.searchParams.get('location'));

        // Days param comes in as a real value, i.e. 3 days... so we need to know where this is in the actual slider, so we convert daysParam to its true slider value
        let daysParamFound = false;
        for (let x = 0; x < packData.days.length; x++) {
            if (packData.days[x] === daysParam) {
                daysParam = x;
                daysParamFound = true;
                break;
            }
        }
        if (!daysParamFound) daysParam = 0;

        // Initialize sliders based on URL params
        daysSlider.value = daysParam >= 0 && daysParam < days.length ? daysParam : 0;
        playersSlider.value = playersParam >= 0 && playersParam < 200 ? playersParam : packData.clusterMaxPlayers ?? 0;
        locationSelect.value = locationParam && Array.from(locationSelect.options).some(option => option.value == locationParam) ? locationParam : locationSelect.options[0].value;

        updateCalculations();
    }
    // We only check URL if its a new order.
    if (!packData.isCluster)
        checkURL();

    // Event listener for the slider to update the tooltip on input
    playerSlider.addEventListener('input', () => {
        updateCalculations();
    });
    daysSlider.addEventListener('input', (e) => {
        updateCalculations();
    });

    // Handle playerSlider change event only if not in cluster mode
    if (!packData.isCluster) {
        playerSlider.addEventListener('change', () => {
            updateCalculations(true);
        });
        daysSlider.addEventListener('change', (e) => {
            updateCalculations(true);
        });
        locationSelect.addEventListener('change', () => {
            updateCalculations(true);
        });
    } else {
        locationSelect.addEventListener('change', () => {
            updateCalculations();
        });
    }

    // Handle form submission
    configureForm.addEventListener('submit', async function (e) {
        e.preventDefault();

        configureFormBtn.disabled = true;
        configureFormBtn.innerHTML = 'Please Wait <div class="spinner-border spinner-border-sm" role="status"></div>';

        alertMessage.HideAlert();

        /*
        if (packData.clusterMaxPlayers) {
            const clusterDays = parseInt(daysInputContent.innerText);
            const clusterPlayers = parseInt(playerSliderContent.innerText);

            if (clusterDays == 0 && clusterPlayers <= packData.clusterMaxPlayers) {
                alertMessage.ShowWarning("You've made no changes to your cluster, so there is nothing to do.");
                configureFormBtn.disabled = false;
                configureFormBtn.innerHTML = 'Update Server Configuration';
                return;
            }
        }*/

        const formData = new FormData(this);
        // Include price for our own validation of this calculator - not actually used so no security risk
        formData.append('price', calculatePrice());
        // Days needs to be adjusted because its currently the value of the slider (0,1,2,3 etc) but we actually need the number of days.
        formData.set('days', packData.days[daysSlider.value]);

        const url = new URL(window.location);
        const gameId = parseInt(url.searchParams.get('gameId'));
        const clusterId = parseInt(url.searchParams.get('clusterId'))|0;

        await transport.PostFormData(`/Checkout/?exec=createOrder&gameId=${gameId}&clusterId=${clusterId}`, formData)
            .then(async data => {
                console.log(data);
                if (typeof data === 'object') {
                    console.log(data);
                    if (data.error) {
                        alertMessage.ShowError(data.message);
                        configureFormBtn.innerHTML = configureFormBtnText;
                        configureFormBtn.disabled = false;
                    } else {
                        // success
                        //
                        if (data.data.url) {
                            window.location = data.data.url;
                        } else {
                            if (data.message.length > 0) {
                                alertMessage.ShowSuccess(data.message);
                                configureFormBtn.innerHTML = configureFormBtnText;
                                configureFormBtn.disabled = false;
                            }
                        }
                    }
                } else {
                    // Raw Text;
                }
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });
    });
})();
